.order-details {
    border-left: 3px solid black;
    padding: 0 1rem;
    list-style-type: none;
    margin-bottom: 2.5rem;
    min-width: 30vw;
}

.name {
    font-size: 1.2rem;
    font-weight: 600;
}

.email {
    font-weight: 300;
    color: gray;
}

.address {
    text-transform: uppercase;
    margin: .5rem 0 .5rem 1rem;
    line-height: 1rem;
    /* word-spacing: .1rem; */
    letter-spacing: .05rem;
    line-height: 1.2rem;
    font-weight: 400;
}

.address * {
    font-family: 'Helvetica', 'Arial', sans-serif;
}

.tag {
    display: inline-block;
    font-size: .5rem;
    margin-right: 1rem;
    width: 3rem;
    /* background: red; */
}

.items {
    list-style-type: none;
    margin: 0;
    padding-left: 1rem;
    /* border: solid red 1px; */
    padding: 1rem 0;
    display: flex;
    flex-flow: column;
    gap: 1rem;
}

.card {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.title {
    font-weight: bold;
}

.sub {
    font-size: .8rem;
}