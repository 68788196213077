.login-form {
    align-self: center;
    display: flex;
    flex-flow: column nowrap;
    padding: 1rem;
    gap: 1.125rem;
    background: rgb(156, 199, 197);
    margin: 1rem;
    padding: 5rem 3rem 2rem 3rem;
    border-radius: 20px;
    width: min(80%, 512px);
}

.login-form > * {
    border-radius: 100px;
    padding: 1rem;
    outline: 0;
    border: 0;
    background: white;
    box-shadow: 0 2px 8px -4px rgba(0, 0, 0, 0.75);
}

.login-form > input:active {
    background: white;
}

.login-form > *:last-child {
    margin-top: 1rem;
    background: rgb(211, 158, 180);
    color: white;
}
