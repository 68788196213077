.Customer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
    padding: 1rem 1rem;
    width: 100%;
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.212);
    border-radius: 4px;
}

.Customer p {
    margin: 0 0 0.5rem 0;
    padding: 0;
}
