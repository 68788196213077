nav {
    flex: 1 1;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
nav > * {
    margin: 0 1rem;
    text-decoration: none;
    font-weight: 700;
    color: black;
}
nav > button {
    outline: 0;
    border: 0;
    background: cornflowerblue;
    color: white;
    padding: 0.25rem 1rem;
    border-radius: 4px;
}
nav > button:hover {
    background: rgb(79, 120, 196);
}
