@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap);
.login-form {
    align-self: center;
    display: flex;
    flex-flow: column nowrap;
    padding: 1rem;
    grid-gap: 1.125rem;
    gap: 1.125rem;
    background: rgb(156, 199, 197);
    margin: 1rem;
    padding: 5rem 3rem 2rem 3rem;
    border-radius: 20px;
    width: min(80%, 512px);
}

.login-form > * {
    border-radius: 100px;
    padding: 1rem;
    outline: 0;
    border: 0;
    background: white;
    box-shadow: 0 2px 8px -4px rgba(0, 0, 0, 0.75);
}

.login-form > input:active {
    background: white;
}

.login-form > *:last-child {
    margin-top: 1rem;
    background: rgb(211, 158, 180);
    color: white;
}

nav {
    flex: 1 1;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
nav > * {
    margin: 0 1rem;
    text-decoration: none;
    font-weight: 700;
    color: black;
}
nav > button {
    outline: 0;
    border: 0;
    background: cornflowerblue;
    color: white;
    padding: 0.25rem 1rem;
    border-radius: 4px;
}
nav > button:hover {
    background: rgb(79, 120, 196);
}

.Customer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
    padding: 1rem 1rem;
    width: 100%;
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.212);
    border-radius: 4px;
}

.Customer p {
    margin: 0 0 0.5rem 0;
    padding: 0;
}

.customers {
    width: 100%;
    padding: 0 1rem;
}

.another {
    margin: 1rem;
    display: flex;
    align-items: space-around;
    justify-content: center;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

.order-details {
    border-left: 3px solid black;
    padding: 0 1rem;
    list-style-type: none;
    margin-bottom: 2.5rem;
    min-width: 30vw;
}

.name {
    font-size: 1.2rem;
    font-weight: 600;
}

.email {
    font-weight: 300;
    color: gray;
}

.address {
    text-transform: uppercase;
    margin: .5rem 0 .5rem 1rem;
    line-height: 1rem;
    /* word-spacing: .1rem; */
    letter-spacing: .05rem;
    line-height: 1.2rem;
    font-weight: 400;
}

.address * {
    font-family: 'Helvetica', 'Arial', sans-serif;
}

.tag {
    display: inline-block;
    font-size: .5rem;
    margin-right: 1rem;
    width: 3rem;
    /* background: red; */
}

.items {
    list-style-type: none;
    margin: 0;
    padding-left: 1rem;
    /* border: solid red 1px; */
    padding: 1rem 0;
    display: flex;
    flex-flow: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.card {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.title {
    font-weight: bold;
}

.sub {
    font-size: .8rem;
}
.App {
    display: flex;
    flex-flow: column nowrap;
    margin: 0;
}

header {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
}

header > h5 {
    padding: 0 1rem;
}

/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
    margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
    list-style: none;
    list-style-type: none;
}

/* Set core root defaults */
html:focus-within {
    scroll-behavior: smooth;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    -webkit-text-decoration-skip: ink;
            text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
    max-width: 100%;
    display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

.link-button {
    text-decoration: none;
    background-color: #777;
    color: white;
    border-radius: 4px;
    padding: 8px;
    margin: 0 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
}


/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    html:focus-within {
        scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
        -webkit-animation-duration: 0.01ms !important;
                animation-duration: 0.01ms !important;
        -webkit-animation-iteration-count: 1 !important;
                animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}
