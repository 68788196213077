.App {
    display: flex;
    flex-flow: column nowrap;
    margin: 0;
}

header {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
}

header > h5 {
    padding: 0 1rem;
}
